// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-biografie-tsx": () => import("./../src/pages/biografie.tsx" /* webpackChunkName: "component---src-pages-biografie-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-praxis-tsx": () => import("./../src/pages/praxis.tsx" /* webpackChunkName: "component---src-pages-praxis-tsx" */),
  "component---src-pages-therapie-autogenes-training-tsx": () => import("./../src/pages/therapie/autogenesTraining.tsx" /* webpackChunkName: "component---src-pages-therapie-autogenes-training-tsx" */),
  "component---src-pages-therapie-holographische-reinkarnations-therapie-tsx": () => import("./../src/pages/therapie/holographischeReinkarnationsTherapie.tsx" /* webpackChunkName: "component---src-pages-therapie-holographische-reinkarnations-therapie-tsx" */),
  "component---src-pages-therapie-imaginative-therapiemit-inneren-bildern-tsx": () => import("./../src/pages/therapie/imaginativeTherapiemitInnerenBildern.tsx" /* webpackChunkName: "component---src-pages-therapie-imaginative-therapiemit-inneren-bildern-tsx" */),
  "component---src-pages-therapie-index-tsx": () => import("./../src/pages/therapie/index.tsx" /* webpackChunkName: "component---src-pages-therapie-index-tsx" */),
  "component---src-pages-therapie-kunstund-gestaltungs-therapie-tsx": () => import("./../src/pages/therapie/kunstundGestaltungs­therapie.tsx" /* webpackChunkName: "component---src-pages-therapie-kunstund-gestaltungs-therapie-tsx" */),
  "component---src-pages-therapie-persoenliches-budget-tsx": () => import("./../src/pages/therapie/persoenlichesBudget.tsx" /* webpackChunkName: "component---src-pages-therapie-persoenliches-budget-tsx" */),
  "component---src-pages-therapie-systemische-paarund-familien-therapie-tsx": () => import("./../src/pages/therapie/systemischePaarundFamilien­therapie.tsx" /* webpackChunkName: "component---src-pages-therapie-systemische-paarund-familien-therapie-tsx" */)
}

